<template>
  <div class="organization">
    <div class="title-bg">
      <div class="title-info title-desc flex">
          <img :src="headerIcon" alt="" class="header-icon">
          <div v-if="leaderName" class="title-info__item">
            <div>站点责任人</div>
            <div>{{leaderName}}</div>
          </div>
          <div class="title-info__item left64">
            <div>所属部门</div>
            <div>{{organization}}</div>
          </div>
      </div>
    </div>

    <div class="content">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="content-item"
              v-for="(item, index) in organizationList"
              :key="index">
            <div class="content-item__header flex">
              <div>{{item.stationName}}</div>
              <div>{{item.createTime}}</div>
            </div>
            <div class="content-item__content">站长：{{item.masterName}}</div>
            <div class="content-item__footer">{{item.stationLocate}}</div>
          </div>
        </van-list>
    </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getMyOrg } from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  components: {
    [Toast.name]: Toast
  },
  data () {
    return {
      organization: '',
      leaderName: '',
      organizationList: [],
      headerIcon: require('@/assets/img/mine/headerIcon.png'),
      org_item_icon: require('@/assets/img/mine/org_item_icon.png'),
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
    }
  },
  mounted () {
    
    uwStatisticAction('/organization','我的组织')
  },
  methods:{
    togetMyOrg(){
      getMyOrg({
        token: common.getItem('wtToken'),
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.organization = res.data.data.deptName
            this.leaderName = res.data.data.leaderName

            this.loading = false;

           this.finished = !res.data.data.pageModel.hasNextPage;
           if (this.pageIndex == 1) {
             //this.organizationList = res.data.data.pageModel.list;
           } else {
             //this.organizationList = this.organizationList.concat(res.data.data.pageModel.list);
           }
           if (!this.finished) {
             this.pageIndex++;
           }
          }
        }
      })
    },
    onRefresh() {
      this.isLoading = false
      this.organizationList = []
      this.pageIndex = 1
      this.togetMyOrg()
    },
    onLoad () {
      this.togetMyOrg()
    },
  }
}
</script>

<style lang="less" scoped>
.organization {
  background: #f5f5f5;
  min-height: 100%;
  // padding: 0.3rem;
  font-size: 0.32rem;
  padding-bottom: 1rem;
  .title-bg {
    height: 2.5rem;
    background: #3f7c53;
    position: relative;
    img {
      width: 6.9rem;
    }
    .title-info {
      position: absolute;
      width: 6.9rem;
      height: 2.14rem;
      border-radius: 0.16rem;
      background: #fff;
      left: 50%;
      top: 40%;
      transform: translateX(-50%);
      padding: 0.38rem 0.3rem;
      align-items: center;
    }
    .header-icon{
      width: 1.36rem;
      height: 1.36rem;
    }
    .left64{
        margin-left: 0.64rem !important;
      }
    .title-info__item{
      margin-left: 0.32rem;
      div {
        &:nth-child(1) {
          color: #999;
          font-size: 0.24rem;
        }
        &:nth-child(2){
          font-size: 0.3rem;
          font-weight: bold;
          margin-top: 0.16rem;
        }
      }
    }
  }
  .flex {
    display: flex;
  }
  .title {
    margin: 0.2rem 0.1rem;
  }
  .content {
    background: #f7f8f9;
    padding: 1rem 0.3rem 0;
    border-radius: 0.2rem;
    .content-item {
      background: #fff;
      width: 6.9rem;
      padding: 0.36rem 0.24rem 0.36rem 0.4rem;
      margin-top: 0.24rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      border-radius: 0.16rem;
      &::before{
        content: '';
        width: 0.12rem;
        height: 100%;
        background:#3F7C53;
        position: absolute;
        left: 0;
        top: 0;
        border-top-left-radius: 0.16rem;
        border-bottom-left-radius: 0.16rem;
      }
      &::after{
        content: '';
        background: url("../../assets/img/mine/org_item_icon.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1.58rem;
        height: 1.2rem;
      }
      .content-item__header {
        justify-content: space-between;
        align-items: flex-start;
        font-size: 0.34rem;
        font-weight: bold;
        div:nth-child(2) {
          font-size: 0.26rem;
          font-weight: normal;
          width: 1.6rem;
          flex: 0 0 1.6rem;
          margin-top: 0.08rem;
        }
      }
      // .content-item__header,
      .content-item__content,
      .content-item__footer {
        color: #999999;
        font-size: 0.3rem;
        margin-top: 0.12rem;
      }
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .content-item__footer{
        word-wrap: break-word;
        word-break: normal;
      }
    }
  }
}
</style>

